@tailwind base;
@tailwind components;
@tailwind utilities;

.wrapper {
  width: 100%;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}

.progress-bar-fill {
  display: block;
  height: 22px;
  background-color: #009900;
  border-radius: 3px;

  transition: width 500ms ease-in-out;
}

.header {
  @apply sticky top-0 w-full items-center py-1.5 px-4 flex justify-around z-50;
  background: linear-gradient(0.25turn, #006632, #58A813);
}